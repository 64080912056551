import React from 'react'
// import {Link} from 'gatsby'
import {
  useIntl,
  Link,
} from "gatsby-plugin-react-intl";
import logo from "../../assets/images/medart_logo.png"
import footerMap from "../../assets/images/footer-map.png"

const Footer = () => {

    const currentYear = new Date().getFullYear();
    const intl = useIntl();

    return (
      <footer className="footer-area bg-color">
        <div className="container">
          <div className="row">
            <div className="col-lg-6 col-sm-12">
              <div className="single-footer-widget">
                <h3>Connect With Medart Technology</h3>
                <a href="/" className="logo">
                  <img src={logo} alt="logo" />
                </a>
                <p>
                  Copyright 2021 © MedArt Technology All rights reserved. Medart
                  Technology's logo, machines, hand pieces pictures and data are
                  registered trademarks of MedArt Technology. Product
                  specifications are subject to change without notice.
                </p>
               </div>
            </div>

            <div className="col-lg-2 col-sm-6">
              <div className="single-footer-widget pl-5">
                <h3>Explore</h3>

                <ul className="footer-links-list">
                  <li>
                    <Link to="/"> {intl.formatMessage({ id: "home" })}</Link>
                  </li>
                  <li>
                    <Link to="/about"> {intl.formatMessage({ id: "about_us" })}</Link>
                  </li>
                  <li>
                    <Link to='/products'>{intl.formatMessage({ id: "products" })}</Link>
                  </li>
                  <li>
                    <Link to="/treatments">{intl.formatMessage({ id: "treatments" })}</Link>
                  </li>
                  <li>
                    <Link to="/international-distributors">{intl.formatMessage({ id: "distributors" })}</Link>
                  </li>
                  <li>
                    <Link to="/faq">  {intl.formatMessage({ id: "faq" })}</Link>
                  </li>
                  <li>
                    <Link to="/contact">{intl.formatMessage({ id: "contact_us" })}</Link>
                  </li>
                </ul>
              </div>
            </div>

            <div className="col-lg-4 col-sm-6">
              <div className="single-footer-widget">
                <h3>FRANCE (Headquarter):</h3>

                <ul className="footer-contact-info">
                  <li>
                    <i className="bx bx-map"></i>
                    223 Rue des Nouvelles Contamines ,<br /> (Numero 5) <br /> 74370 Annecy
                  </li>
                  <li>
                    <i className="bx bx-phone-call"></i>
                    <a href="tel:+33 7 71 74 40 77">+33 7 71 74 40 77</a>
                  </li>
                  <li>
                    <i className="bx bx-envelope"></i>
                    <a href="mailto:contact@medart-technology.info">contact@medart-technology.info</a>
                  </li>
                </ul>
              </div>
            </div>
          </div>

          <div className="footer-bottom-area">
            <div className="row align-items-center">
              <div className="col-lg-6 col-md-6">
                <p>
                  Copyright @{currentYear} <strong>MedArt Technology</strong> Designed by:{" "}
                  <a
                    target="_blank"
                    href="https://incarts.info/"
                    rel="noreferrer"
                  >
                    INCARTS
                  </a>
                </p>
              </div>

              {/* <div className="col-lg-6 col-md-6">
                <ul>
                  <li> 
                    <Link to="/privacy-policy">Privacy Policy</Link>
                  </li>
                  <li>
                    <Link to="/terms-of-service">Terms & Conditions</Link>
                  </li>
                </ul>
              </div> */}
            </div>
          </div>
        </div>

        <div className="footer-map">
          <img src={footerMap} alt="footer-logo" />
        </div>
      </footer>
    );
}

export default Footer;
