import React from "react";
import { RecoilRoot } from "recoil";
import MessengerCustomerChat from "react-messenger-customer-chat";
import { useIntl } from "gatsby-plugin-react-intl";
import GoTop from "./GoTop";
import SEO from "./SEO";
import logo from "../../assets/images/medart_logo.png";

const Layout = ({
  title,
  description,
  canonical,
  image,
  children,
  language,
  pageNotFound,
}) => {
  const intl = useIntl();
  const current_language = intl.locale === "en" ? "en_US" : "fr_FR";
  return (
    <RecoilRoot>
      <MessengerCustomerChat
        pageId="1130830686983576"
        appId="2872451339696991"
        language={current_language}
      />
      <SEO
        title={title}
        pageNotFound={pageNotFound}
        current_language={current_language}
        language={intl.locale}
        description={description}
        canonical={canonical}
        image={image || logo}
      />
      {children}
      <GoTop scrollStepInPx="100" delayInMs="10.50" />
    </RecoilRoot>
  );
};

export default Layout;
