import React from "react";
import { useRecoilState } from "recoil";
import { collapsedState } from "../../utils/recoil-atoms";
import { changeLocale, useIntl, Link } from "gatsby-plugin-react-intl";
import { graphql, useStaticQuery } from "gatsby";
import logo from "../../assets/images/medart_logo.png";
import UsFlag from "../../images/Flags/united-states.png";
import FrFlag from "../../images/Flags/france.png";

export const query = graphql`
  {
    allContentfulTreatment {
      nodes {
        name
        name_fr
        slug
      }
    }
    allContentfulCategory {
      nodes {
        name
        name_fr
        slug
        products {
          name
          nameFr
          slug
        }
      }
    }
  }
`;

const NavbarTwo = () => {
  const [collapsed, setCollapsed] = useRecoilState(collapsedState);
  const intl = useIntl();
  const toggleNavbar = () => {
    setCollapsed(!collapsed);
  };

  const toggleLanguage = (value) => {
    // setCollapsed(true);
    changeLocale(value);
  };

  const getCurrentLanguage = () => {
    if (intl.locale === "en") {
      return (
        <>
          <span>EN</span> <img src={UsFlag} alt="Us flag" />
        </>
      );
    }
    return (
      <>
        <span>FR</span> <img src={FrFlag} alt="Fr flag" />
      </>
    );
  };

  React.useEffect(() => {
    let elementId = document.getElementById("navbar");
    document.addEventListener("scroll", () => {
      if (window.scrollY > 170) {
        elementId.classList.add("is-sticky");
      } else {
        elementId.classList.remove("is-sticky");
      }
    });
    window.scrollTo(0, 0);
  });

  const {
    allContentfulCategory: { nodes: categories },
    allContentfulTreatment: { nodes: treatments },
  } = useStaticQuery(query);

  const classOne = collapsed
    ? "collapse navbar-collapse"
    : "collapse navbar-collapse show";
  const classTwo = collapsed
    ? "navbar-toggler navbar-toggler-right collapsed"
    : "navbar-toggler navbar-toggler-right";

  return (
    <React.Fragment>
      <div id="navbar" className="navbar-area navbar-style-two">
        <div className="tarn-nav">
          <div className="container">
            <nav className="navbar navbar-expand-lg navbar-light">
              <Link
                to="/"
                onClick={() => setCollapsed(true)}
                className="navbar-brand"
              >
                <img src={logo} alt="logo" />
              </Link>

              <button
                onClick={toggleNavbar}
                className={classTwo}
                type="button"
                data-toggle="collapse"
                data-target="#navbarSupportedContent"
                aria-controls="navbarSupportedContent"
                aria-expanded="false"
                aria-label="Toggle navigation"
              >
                <span className="icon-bar top-bar"></span>
                <span className="icon-bar middle-bar"></span>
                <span className="icon-bar bottom-bar"></span>
              </button>

              <div className={classOne} id="navbarSupportedContent">
                <ul className="navbar-nav">
                  <li className="nav-item">
                    <Link
                      to="/"
                      activeClassName="active"
                      onClick={(e) => setCollapsed(true)}
                      className="nav-link"
                    >
                      {intl.formatMessage({ id: "home" })}
                    </Link>
                  </li>

                  <li className="nav-item">
                    <Link
                      to="/about"
                      activeClassName="active"
                      onClick={(e) => setCollapsed(true)}
                      className="nav-link"
                    >
                      {intl.formatMessage({ id: "about_us" })}
                    </Link>
                  </li>

                  <li className="nav-item has-dropdown">
                    <Link
                      to={`/products`}
                      activeClassName="active"
                      onClick={(e) => setCollapsed(true)}
                      className="nav-link"
                    >
                      {intl.formatMessage({ id: "products" })}
                      {typeof window !== "undefined" &&
                        window.innerWidth > 767 && (
                          <i className="bx bx-chevron-down"></i>
                        )}
                    </Link>
                    {typeof window !== "undefined" && window.innerWidth < 767 && (
                      <span>
                        <i className="bx bx-chevron-down"></i>
                      </span>
                    )}
                    <ul className="dropdown-menu">
                      {categories?.map((category, i) => {
                        return (
                          <li className="nav-item has-dropdown text-capitalize">
                            <Link
                              to={`/${
                                intl.locale === "en"
                                  ? "product-category"
                                  : "categorie-produit"
                              }/${category.slug}`}
                              activeClassName="active"
                              className="nav-link text-capitalize"
                            >
                              {intl.locale === "en"
                                ? category.name
                                : category.name_fr}
                              {typeof window !== "undefined" &&
                                window.innerWidth > 767 && (
                                  <i className="bx bx-chevron-down"></i>
                                )}
                            </Link>
                            {typeof window !== "undefined" &&
                              window.innerWidth < 767 && (
                                <span>
                                  <i className="bx bx-chevron-down"></i>
                                </span>
                              )}
                            <ul className="dropdown-menu">
                              {category?.products?.map((product, i) => {
                                const productName =
                                  intl.locale === "en"
                                    ? product?.name
                                    : product?.nameFr || product?.name;
                                return (
                                  <li className="nav-item text-capitalize">
                                    <Link
                                      to={`/${
                                        intl.locale === "en"
                                          ? "product"
                                          : "produit"
                                      }/${product.slug}`}
                                      activeClassName="active"
                                      onClick={() => setCollapsed(true)}
                                      className="nav-link text-capitalize"
                                    >
                                      {productName}
                                    </Link>
                                  </li>
                                );
                              })}
                            </ul>
                          </li>
                        );
                      })}
                    </ul>
                  </li>

                  <li className="nav-item has-dropdown">
                    <Link
                      to="/treatments"
                      activeClassName="active"
                      onClick={(e) => setCollapsed(true)}
                      className="nav-link"
                    >
                      {intl.formatMessage({ id: "treatments" })}
                      {typeof window !== "undefined" &&
                        window.innerWidth > 767 && (
                          <i className="bx bx-chevron-down"></i>
                        )}
                    </Link>
                    {typeof window !== "undefined" && window.innerWidth < 767 && (
                      <span>
                        <i className="bx bx-chevron-down"></i>
                      </span>
                    )}
                    <ul className="dropdown-menu">
                      {treatments?.map((treatment, i) => {
                        return (
                          <li className="nav-item">
                            <Link
                              to={`/treatments/${treatment.slug}`}
                              activeClassName="active"
                              onClick={() => setCollapsed(true)}
                              className="nav-link text-capitalize"
                            >
                              {intl.locale === "en"
                                ? treatment.name
                                : treatment.name_fr}
                            </Link>
                          </li>
                        );
                      })}
                    </ul>
                  </li>

                  <li className="nav-item">
                    <Link
                      to="/international-distributors"
                      activeClassName="active"
                      onClick={(e) => setCollapsed(true)}
                      className="nav-link"
                    >
                      {intl.formatMessage({ id: "distributors" })}
                    </Link>
                  </li>

                  <li className="nav-item">
                    <Link
                      to="/faq"
                      activeClassName="active"
                      onClick={(e) => setCollapsed(true)}
                      className="nav-link"
                    >
                      {intl.formatMessage({ id: "faq" })}
                    </Link>
                  </li>

                  <li className="nav-item">
                    <Link
                      to="/contact"
                      activeClassName="active"
                      onClick={(e) => setCollapsed(true)}
                      className="nav-link"
                    >
                      {intl.formatMessage({ id: "contact_us" })}
                    </Link>
                  </li>

                  <li className="nav-item has-dropdown">
                    <Link
                      to="#"
                      activeClassName="active"
                      onClick={(e) => setCollapsed(true)}
                      className="nav-link"
                    >
                      {getCurrentLanguage()}
                      {typeof window !== "undefined" &&
                        window.innerWidth > 767 && (
                          <i className="bx bx-chevron-down"></i>
                        )}
                    </Link>
                    {typeof window !== "undefined" && window.innerWidth < 767 && (
                      <span>
                        <i className="bx bx-chevron-down"></i>
                      </span>
                    )}
                    <ul className="dropdown-menu">
                      <li
                        className="nav-item"
                        onClick={() => toggleLanguage("en")}
                      >
                        <a
                          activeClassName="active"
                          className={`nav-link ${
                            intl.locale === "en" && "active"
                          }`}
                        >
                          EN <img src={UsFlag} alt="Us flag" />
                        </a>
                      </li>

                      <li
                        className="nav-item"
                        onClick={() => toggleLanguage("fr")}
                      >
                        <a
                          activeClassName="active"
                          className={`nav-link ${
                            intl.locale === "fr" && "active"
                          }`}
                        >
                          FR <img src={FrFlag} alt="Fr flag" />
                        </a>
                      </li>
                    </ul>
                  </li>

                  {/* <li className="nav-item">
                    <Link
                      to="#"
                      activeClassName="active"
                      onClick={(e) => setCollapsed(true)}
                      className="nav-link"
                    >
                      Blog
                    </Link>
                  </li> */}
                </ul>

                <div className="others-option d-flex align-items-center">
                  <div className="option-item">
                    {/* <Link 
                                            to="/contact" 
                                            activeClassName="active"
                                            onClick={() => setCollapsed(true)}
                                            className="default-btn"
                                        >
                                            <i className="flaticon-right"></i> Get Started <span></span>
                                        </Link> */}
                  </div>
                </div>
              </div>
            </nav>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default NavbarTwo;
