import React from 'react'
import { Helmet } from "react-helmet"

const SEO = ({title, description, canonical,image,pageNotFound, hashtag, language, current_language}) => {
    const hashtags = hashtag || '#medart_technology';
    const des = description || 'MedArt Technology is a leading visionary pioneer within the medical aesthetics and manufacturing industry with over 10 years of successfully producing, distributing, and manufacturing aesthetics machinery and providing reliable unprecedented services.The integrated skills and expertise of MedArt Technology’s team have focused on creating new venture models to broaden and underpin the group’s success by expanding in a number of geographical regions.MedArt technology’s team has been paving the way towards strategic growth with game-changing business projects on International platforms within 12 years being active and successful in different kinds of businesses as Medical Aesthetic Equipment, Real Estate, Construction & Development, Industrial Laser Equipment, Marketing & Development.'
    
    return (
        <div>
            <Helmet htmlAttributes={{
                lang: language || 'en',
            }}>
                <title>MedArt Technology - {title || 'Laser Devices Manufacturer'}</title>
                <meta charset="utf-8" />
                <meta http-equiv="X-UA-Compatible" content="IE=edge" />
                <meta property="type" content="website" />
                {!pageNotFound && <meta property="url" content={canonical} /> }
                <meta
                    name="viewport"
                    content="width=device-width, initial-scale=1, shrink-to-fit=no"
                />
                <meta name="msapplication-TileImage" content="/ms-icon-144x144.png" />
                {!pageNotFound ? <meta name="robots" content="noodp" /> : <meta name="robots" content="noimageindex, nofollow"></meta>}
                <meta property="title" content={`MedArt Technology - ${title || 'MedArt Technology Laser Devices Manufacturer'}`} />
                <meta name="description" content={des} />
                <meta property="image" content={image} />
                <meta property="og:locale" content={current_language} />
                <meta property="og:type" content="website" />
                <meta property="og:title" content={`MedArt Technology - ${title || 'MedArt Technology Laser Devices Manufacturer'}`} />
                <meta property="og:hashtag" content={hashtags} />
                <meta property="og:image" content={image} />
                {!pageNotFound && <link rel="canonical" href={canonical}></link> }
                <meta content="image/*" property="og:image:type" />
                {!pageNotFound && <meta property="og:url" content={canonical} /> }
                <meta property="og:site_name" content="Medart" />
                <meta name="twitter:title" content={`MedArt Technology - ${title || 'MedArt Technology Laser Devices Manufacturer'}`} />
                <meta name="twitter:description" content={des} />
                <meta name="twitter:image" content={image} />
                <meta name="twitter:card" content="summary_large_image"></meta>
                <meta property="og:description" content={des} />
            </Helmet>
        </div>
    )
}

export default SEO
